<template>
  <section>
    <a-page-header title="Карты">
      <template #extra>
        <search-expandable size="large" placeholder="Поиск по названию и коду" v-model="search"
                           @enter="fetchList"/>
      </template>
    </a-page-header>

    <main>
      <a-table :columns="columns" :data-source="data" row-key="id" :pagination="pagination"
               :locale="{ emptyText: 'Ничего не найдено' }" :loading="loading"
               @change="handleTableChange">
        <template #name="{ record, text }">
          <router-link :to="{ name: 'mapViewAdmin', params: { id: record.id } }" class="text-color-text">
            {{ text }}
          </router-link>
        </template>
        <template #actions="{ record }">
          <a-space size="small">

            <a-button size="large" type="default" :style="{ width: '160px' }">
              <router-link :to="{ name: 'mapViewAdmin', params: { id: record.id } }">Перейти</router-link>
            </a-button>

            <a-dropdown :trigger="['click']">
              <template #overlay>
                <a-menu v-if="record?.id === 'root'">
                  <a-menu-item key="edit">
                    <router-link :to="{ name: 'objectsSvgMap' }">
                      <edit-outlined/>
                      Редактировать
                    </router-link>
                  </a-menu-item>
                </a-menu>
                <a-menu v-else>
                  <a-menu-item key="edit">
                    <router-link :to="{ name: 'objectView', params: { id: record.id }, hash: '#map' }">
                      <edit-outlined/>
                      Редактировать
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="map" v-if="record?.hasChildren">
                    <router-link :to="{ name: 'objectChildren', params: { id: record.id }}">
                      <apartment-outlined/>
                      В структуре
                    </router-link>
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button type="dashed" key="more" size="large">
                <template #icon>
                  <more-outlined/>
                </template>
              </a-button>
            </a-dropdown>
          </a-space>
        </template>

      </a-table>

    </main>
  </section>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { MoreOutlined, EditOutlined, ApartmentOutlined } from '@ant-design/icons-vue';
import { getMapListData } from "@/compositions/maps";
import SearchExpandable from "@/components/common/SearchExpandable";

export default {
  name: "MapsListPage",
  setup() {
    const {
      loading, search,
      fetchList, pagination,
      columns, data,
      handleTableChange
    } = getMapListData();

    onMounted(async () => {
      await fetchList();
    });

    return {
      loading,
      fetchList, pagination,
      columns, data, search,
      handleTableChange
    }
  },
  components: {
    SearchExpandable,
    MoreOutlined, EditOutlined, ApartmentOutlined,
  }
}
</script>
