import Axios from "axios";
import { urlHelper } from "@/compositions/commonFunctions";
import { store } from "@/store";
import { reactive, ref } from "@vue/reactivity";
import { unref } from "@vue/runtime-core";

export function requestAPI() {

  // Получение списка объектов
  async function fetchMapObjects(params) {
    try {
      return await Axios.get(`/map/objects` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  return { fetchMapObjects }
}

export function getMapListData() {
  const loading = ref(false);

  const columns = [
    {
      title: 'Объект',
      dataIndex: 'name',
      key: 'name',
      slots: { customRender: 'name' },
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
    },
    {
      className: 'text-right',
      slots: { customRender: 'actions', title: 'actionsTitle' },
    },
  ];

  const data = ref([]);
  const search = ref('');
  const pagination = reactive({ hideOnSinglePage: true });
  const { fetchMapObjects } = requestAPI();

  async function handleTableChange(tablePagination) {
    pagination.current = tablePagination.current;
    await fetchList();
  }

  async function fetchList() {
    try {
      loading.value = true;
      const res = await fetchMapObjects({
        page: pagination.current,
        perPage: pagination?.pageSize,
        search: unref(search),
      });
      //
      data.value = res.data.map((item) => ({
        ...item,
        id: item?.name === 'root' ? 'root' : item?.id,
        name: item?.name === 'root' ? 'Карта корня' : item?.name,
      }));
      pagination.pageSize = +res.meta.perPage;
      pagination.total = +res.meta.total;
      pagination.current = +res.meta.currentPage;
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading, search,
    columns, data, pagination,
    handleTableChange, fetchList,
  };
}
